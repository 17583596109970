import "../AuthStyle.css";
import { Row, Col, Form, Input, message, Button, Radio } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  USER_TYPE_REFERRAL,
  USER_TYPE_RESELLER,
} from "../../../configs/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const { appConfig, setAppConfigWrapper, setAuthUser, setFullPageLoading } =
    useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (
          response.data &&
          response.data.success &&
          (response.data.data.user.type === USER_TYPE_REFERRAL ||
            response.data.data.user.type === USER_TYPE_RESELLER)
        ) {
          localStorage.setItem(
            "access_token",
            response.data.data.user.access_token
          );
          setAuthUser(response.data.data.user);
          navigate("/home");
        } else {
          let error = response.response;
          if (error?.status === 422) {
            message.error(error.data.message);
          } else if (error?.status === 500) {
            let errorMessages = Object.values(error.data.data.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          } else {
            message.error(t("something_went_wrong"));
          }
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };
  return (
    <div className="authSection ">
      <div className="authInnerSection">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="authSection-left"
          >
            <div className="lang_button">
              <Radio.Group
                value={appConfig.direction}
                onChange={(e) => {
                  setAppConfigWrapper({ direction: e.target.value });
                }}
                buttonStyle="solid"
                defaultValue="ltr"
              >
                <Radio.Button value="ltr">English</Radio.Button>
                <Radio.Button value="rtl">عربي</Radio.Button>
              </Radio.Group>
            </div>
            <div
              style={{
                maxWidth: "300px",
                Width: "100%",
                margin: "20px auto",
              }}
            >
              <img src="/images/team.jpg" alt="AnyPOS Logo" />
              <p>This is a dummy text placing for the set the layout.</p>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <a href="https://anypos.app/" target="_blank" rel="noreferrer">
                  <Button type="primary">{t("visit_website")}</Button>
                </a>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="authSection-right"
          >
            <div
              style={{
                maxWidth: "300px",
                Width: "100%",
                margin: "20px auto",
              }}
            >
              <h2 className="authSection-heading">{t("login")}</h2>
              <Form
                name="login-user-form"
                layout="vertical"
                onFinish={onFinish}
              >
                <Form.Item
                  name="phone"
                  label={t("phone_number") + " (eg: +966 5xxxxxxx)"}
                  rules={[
                    {
                      required: true,
                      message: t("validate_phone_required"),
                    },
                    {
                      min: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                    {
                      max: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                  ]}
                >
                  <PhoneInput
                    localization={["sa"]}
                    country={"sa"}
                    onlyCountries={["sa"]}
                    masks={{ sa: "(...) ..-..-.." }}
                    placeholder="+966 (123) 45-67-89"
                    style={{
                      width: "100%",
                      direction: "ltr",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Link to="/forgot">{t("forgot_password")}</Link>
                <Form.Item style={{ margin: "20px 0" }}>
                  <Button size="large" block type="primary" htmlType="submit">
                    {t("login")}
                  </Button>
                </Form.Item>
              </Form>
              <center>
                {t("dont_have_account")}{" "}
                <Link to="/register">{t("sign_up")}</Link>
              </center>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;

const trans_en_GB = {
  // Form Fields
  name: "Name",
  first_name: "First Name",
  last_name: "Last Name",
  name_ar: "Name in Arabic",
  name_en: "Name in English",
  price: "Price",
  email: "E-mail",
  password: "Password",
  confirm_password: "Confirm Password",
  phone_number: "Phone Number",
  business_name: "Business Name",
  business_type: "Business Type",
  shop_address: "Shop Address",
  cr_number: "CR number",
  vat_number: "VAT Number",
  business_logo: "Business Logo",
  image: "Image",
  is_taxable: "Is Taxable",
  is_refunded: "Is Refunded",
  is_QRproduct: "Is QR Product",
  is_stockable: "Is Stockable",
  reset: "Reset",
  cart: "Cart",
  address: "Address",
  company: "Company",
  code: "Code",
  branch: "Branch",
  active: "Active",
  inactive: "Inactive",
  kyc: "KYC",
  in_review: "In Review",

  no_of_employees: "# of Employees",
  no_of_licenses: "# of Licenses",
  vat_certificate: "VAT Certificate",
  cr_certificate: "CR Certificate",
  change: "Change",
  preview: "Preview",
  billing_address: "Billing Address",
  billing_city: "City",
  billing_state: "State",
  billing_country: "Country",
  billing_post_code: "Postal Code",
  no_attachment: "No Attachment",

  // Form Validations
  validate_email_required: "The email field is required.",
  validate_email_invalid: "The input is not a valid E-mail!",
  validate_password_required: "The password field is required.",
  validate_password_min_characters: "password must be of minimum 6 characters.",
  validate_password_confirm_not_matched: "Confirm password does not matched.",
  validate_name_required: "The name field is required.",
  validate_first_name_required: "The first name field is required.",
  validate_first_name_min_characters:
    "First name must be of minimum 3 characters.",
  validate_last_name_required: "The last name field is required.",
  validate_last_name_min_characters:
    "Last name must be of minimum 3 characters.",
  validate_phone_required: "The phone number field is required.",
  validate_phone_exact_limit: "Phone must be of exactly 12 digits.",
  validate_address_required: "The address field is required.",
  validate_image_file_type: "You can only upload JPG/PNG file!",
  validate_image_size_2: "Image must be smaller than 2MB!",
  validate_file_size_5: "file must be smaller than 5MB!",

  // Login
  dont_have_account: "Don't have an account?",
  login: "Login",
  visit_website: "Visit website",

  // Register
  already_have_an_account: "Already have an account?",
  sign_up: "Sign up",

  // Forgot
  forgot: "Forgot password",
  forgot_password: "Forgot password?",
  send_otp: "Send OTP",
  verify_otp: "Verify OTP",
  reset_password: "Reset password",
  reset_password_successfully: "Your password reset Successfully!",

  // otp form
  opt_message:
    "A message with the OTP code has been sent on your mobile number. Kindly fill in the below field and Validate.",
  if_you_want_to_change_phone_number: "If you want to change phone number",
  click_here: "Click Here.",
  resend_code_in: "Resend Code In",
  validate: "Validate",
  allow_round_off: "Allow round off to 2 digits",
  preferred_contact_time: "Preferred contact time",

  // Menus
  menu_home: "Home",
  menu_companies: "Companies",
  menu_help_desk: "Help Desk",
  menu_profile: "Profile",
  menu_commission: "Commission",
  menu_payout_detail: "Payout Detail",

  // Branches
  branch_add: "Add Branch",
  branch_added: "Branch Added",
  branch_added_successfully: "Branch has been added successfully!",
  branch_deleted: "Branch Deleted",
  branch_deleted_successfully: "Branch has been deleted successfully!",
  branch_edit: "Edit Branch",
  branch_updated: "Branch Updated",
  branch_updated_successfully: "Branch has been updated successfully!",

  // invoices
  model: "Model",
  imei: "IMEI",
  serial_no: "Serial No.",
  amount: "Amount",
  due_amount: "Due Amount",
  installments: "Installments",
  warranty_from_to: "Warranty From - To",

  // Table Columns
  col_product: "Product",
  col_price: "Price",
  col_category: "Category",
  col_unit: "Unit",
  col_barcode: "Barcode",
  col_action: "Action",
  col_order_id: "Order ID",
  col_amount: "Amount",
  col_tax: "VAT on Sale",
  type: "Type",
  col_quantity: "Quantity",
  status: "Status",
  col_created_at: "Created At",
  col_created_by: "Created by",
  col_subtotal: "SubTotal",
  col_sold_quantities: "Sold Quantities",
  col_gross_sales: "Gross Sales",
  col_refund_quantities: "Refund Quantities",
  col_gross_refund: "Gross Refunds",
  col_start_date: "Start Date",
  col_end_date: "End Date",
  date: "Date",
  col_subscription_plan: "Subscription Plan",
  col_transaction_id: "Transaction ID",
  col_discount_percentage: "Discount Percentage",
  col_branches: "Branches",
  invoice_ID: "Invoice ID",
  subscription: "Subscription",
  license: "License",
  total_amount_charged: "Total Amount Charged",
  col_items: "Items",
  col_user: "User",
  col_payment: "Payment",
  col_new: "New Tickets",
  col_inprogress: "Tickets In Progress",
  col_done: "Tickets Done",
  col_closed: "Tickets Closed",
  col_description: "Description",
  col_attachment: "Attachment",
  col_allow_refund: "Allow Refund",
  col_allow_add_products: "Allow Add Products",
  col_allow_edit_products: "Allow Edit Products",
  col_odoo_status: "Odoo status",
  col_source: "Source",
  col_pending_orders: "Pending Orders",
  col_inprogress_orders: "Inprogress Orders",
  col_completed_orders: "Completed Orders",
  col_cancelled_orders: "Cancelled Orders",
  col_branch_from: "Branch From",
  col_branch_to: "Branch To",
  col_customer_name: "Customer Name",
  col_contact: "Contact",
  assigned_to: "Assigned To",

  // Warnings
  warning_delete: "Are you sure to delete this entity?",

  // Miscellaneous
  yes: "Yes",
  no: "No",
  logout: "Logout",
  cancel: "Cancel",
  add: "Add",
  edit: "Edit",
  update: "Update",
  submit: "Submit",
  search: "Search",
  upload: "Upload",
  back: "Back",
  order_id: "Order ID",
  order_details: "Order Details",
  issued_at: "Issued At",
  date_range: "Date Range",
  delete_entity: "Delete Entity",
  something_went_wrong: "Something went wrong",
  refund: "Refund",
  user_can_edit_price_on_pos: "User Can Edit Price on POS",
  user_can_apply_discount: "User Can Apply Discount",
  user_see_transactions: "User Can See The Transactions",
  user_can_refund_transaction: "User Can Refund Transaction",
  user_can_add_products: "User Can Add Products",
  user_can_edit_products: "User Can Edit The Products",
  user_can_request_stock_transfer: "User Can Request Stock Transfer",
  user_can_approve_stock_transfer: "User Can Approve Stock Transfer",
  is_machine_user: "Is Machine User",
  proceed: "Proceed",
  checkout: "Checkout",
  payment_type: "Payment Type",
  cash_collected: "Cash Collected",
  reference_no: "Reference No.",
  collected_by_cash: "Collected by Cash",
  collected_by_card: "Collected by Card",
  charged_amount: "Charged Amount",
  including_vat: "Including VAT",
  ask_more_cash: "Ask for more cash before proceeding!",
  return_back_amount: "Return Back",
  collect_more_cash: "Collect More Cash",
  print: "Print",
  declaration: "Declaration",
  declaration_text:
    "I hereby declare that the information provided in this form is accurate and complete. I confirm that any information is found incorrect and/or incomplete that leads a violation of regulations may initiate legal actions, and I accept that I am the responsible party for any and all charges, penalties and violations.",
  upgrade: "Upgrade",
  upgrade_subscription: "Upgrade Subscription",
  payment_successful: "Payment Successful!",
  your_payment_successfully: "Your payment has been successful.",
  payment_failed: "Payment Failed!",
  try_again_payment_failed: "Your payment has been failed. Please try again.",
  all: "All",
  cart_empty: "Your Cart is empty.",
  total_amount: "Total Amount",
  licenses: "License(s)",
  purchase: "Purchase",
  total_taxes: "Total VAT on Sales",
  credit_card: "Credit Card",
  stc_pay: "STC Pay",
  mada: "MADA",
  cash: "Cash",
  card: "Card",
  multi_payment: "Multi Payment",
  plz_fill_details: "Please fill the details below, to move further",
  billing_details: "Billing Details",
  active_subscription: "Active Subscription",
  available_upgrades: "Available Upgrades",
  already_requested: "Already Requested",
  request_upgrade: "Request Upgrade",
  request_subscription_upgrade: "Request Subscription Upgrade",
  pro_plan: "Pro Plan",
  basic_plan: "Basic Plan",
  discount: "Discount",
  select_a_discount: "Select a Discount",
  total_amount_payable: "Total Amount Payable",
  unpaid_invoice: "Unpaid Invoice!",
  pay_your_invoice: "Kindly pay your unpaid Invoice",
  show_invoices: "Show Invoices",
  what_type_of_refund: "What type of refund you want to perform?",
  full: "Full",
  partial: "Partial",
  refund_amount: "Refund Amount",
  qty: "Qty",
  year: "Year",
  month: "Month",
  day: "Day",
  additional: "Additional",
  users: "Users",
  pay_on: "Pay on",
  action: "Action",
  pay_now: "Pay Now",
  view_invoice: "View Invoice",
  one_time_payment: "One Time Payment",
  months_plan: " Months Plan",
  complete: "Complete",
  incomplete: "Incomplete",

  is_vat_exempt: "I don't have VAT certificate",

  connection: "Connection",
  connected: "Connected",
  disconnected: "Disconnected",

  skip: "Skip",

  kindly_complete_the_store_details_within:
    "Kindly complete the store details within",
  days_otherwise_your_account_will_be_blocked:
    "days otherwise your account will be blocked.",
  account_freezed: "Your account is freezed!",
  upgrade_subscription_unfreeze:
    "Kindly select a subscription upgrade to unfreeze it.",
  pay_generated_invoice_first: "Pay Generated Invoice First",
  you_selected_following_package: "You selected following package",
  unlimited: "Unlimited",
  per_user: "Per User",
  payment_modal: "Payment Modal",
  select_payment_method: "Select Payment Method",
  service_name: "Service Name",
  subscriptions_history: "Subscriptions History",
  spending_history: "Spending History",
  trial_days: "Trial Days",
  paid: "Paid",
  unpaid: "Unpaid",
  qr: "QR",
  dining_table: "Dining Table",
  note: "Note",
  clear: "Clear",
  selected_item: "Selected Item",
  pending: "Pending",
  completed: "Completed",
  cancelled: "Cancelled",
  rejected: "Rejected",
  available_stock: "Available Stock",
  requested_stock: "Requested Stock",
  current_stock: "Current Stock",
  your_branch: "Your Branch",
  approved_quantity: "Approved Quantity",
  requested_quantity: "Requested Quantity",
  insufficient_quantity: "Insufficient Quantity",
  out_of_stock: "Out of Stock",
  product_already_selected: "Product already Selected",

  individual: "Individual",
  photo_of_id: "Photo of ID",
  validate_photo_of_id_required: "Photo of ID is required",
  company_name: "Company Name",
  validate_type_required: "The Type field is required.",
  validate_company_name_required: "The Company name field is required.",
  company_registration_document: "Company Registration Document",
  validate_company_registration_document_required:
    "The Company registration document field is required.",
  wallet_detail: "Wallet Detail",
  bank_detail: "Bank Detail",
  payout_history: "Payout History",
  available_balance: "Available Balance",
  level_config: "Level Configuration",
  account_title: "Account Title",
  bank_name: "Bank Name",
  account_number: "Account Number",
  branch_code: "Branch Code",
  city: "City",
  country: "Country",
  reseller_number: "Reseller Number",
  target: "Target",
  retain_rate: "Retain Rate",
  commission: "Commission",
  total: "Total",
  balance_details: "Balance Details",
  reference_number: "Reference Number",
  add_company: "Add Company",
  forward_to_csr: "Forward to CSR",
  mark_new: "Mark as New",
  mark_inprogress: "Mark as in Progress",
  mark_done: "Mark as Done",
  mark_closed: "Mark as Closed",

  // Helpdesk
  new_tickets: "New Tickets",
  inprogress_tickets: "Tickets In Progress",
  done_tickets: "Tickets Done",
  closed_tickets: "Tickets Closed",
  ticket_updated: "Ticket Updated",
  ticket_updated_successfully: "The ticket has been updated successfully!",

  status_updated_successfully: "Status updated Successfully!",
  helpdesk_ticket_status_updated_successfully:
    "Helpdesk Ticket status updated Successfully!",

  hi: "Hi",
  thankyou_for_applying_account: "Thank you for applying for an account!",
  account_pending_message:
    "Your account is currently pending approval by the site administrator.",
  account_rejection: "Unfortunately your account has been rejected!",
  account_under_review: "Your account is under review!",
  account_under_review_details:
    "It usually takes us around a day. Until the review is completed, You won't able to do anything",
  account_blocked: "Your account is blocked!",
  kindly_contact_at: "Kindly contact us at contact@anypos.app",

  basic_level: "Basic Level",
  pro_level: "Pro Level",
};

export default trans_en_GB;

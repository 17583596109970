import React, { useContext } from "react";
import { Button, Popover, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { forwardTickets, updateTickets } from "../../../network/network";
import { EyeOutlined, MoreOutlined, ToolOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContextProvider";

const TicketsTable = ({
  tickets,
  currentTab,
  reloadCallback,
  loading,
  paginationData,
}) => {
  const { setActiveMenu } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const TicketsTableColumns = [
    {
      title: "#",
      align: "center",
      width: "50px",
      key: "index",
      render: (text, record, index) => {
        return (
          <p>
            {paginationData.current_page
              ? paginationData.per_page * (paginationData.current_page - 1) +
                (index + 1)
              : index + 1}
          </p>
        );
      },
    },
    {
      title: t("col_description"),
      dataIndex: "description",
    },
    {
      title: t("assigned_to"),
      dataIndex: "manage_by",
      width: "120px",
      render: (manage_by) => {
        return (
          <>
            <span>{manage_by ? manage_by.name : "-"}</span>
          </>
        );
      },
    },
    {
      title: t("col_customer_name"),
      dataIndex: "created_by",
      width: "160px",
      render: (created_by) => {
        return (
          <>
            <span
            // style={{ cursor: "pointer" }}
            // onClick={() => {
            //   setActiveMenu("companies");
            //   navigate({
            //     pathname: "/companies/" + created_by.company_id,
            //   });
            // }}
            >
              {created_by.name}
            </span>
          </>
        );
      },
    },
    {
      title: t("col_attachment"),
      dataIndex: "attachment",
      width: "120px",
      render: (attachment) => {
        return (
          <>
            <div>
              {attachment ? (
                <Button
                  type="primary"
                  href={attachment}
                  size="small"
                  target="_blank"
                >
                  <EyeOutlined /> {t("preview")}
                </Button>
              ) : (
                <div>-</div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("col_contact"),
      dataIndex: "created_by",
      width: "120px",
      render: (created_by) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveMenu("companies");
                navigate({
                  pathname: "/companies/" + created_by.company_id,
                });
              }}
            >
              {created_by.phone}
            </span>
          </>
        );
      },
    },
    {
      title: t("col_action"),
      width: "120px",
      align: "center",
      render: (ticket) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => forwardTicketToCSR(ticket.id)}
            disabled={ticket.manage_by.id !== ticket.reseller_agent.id}
          >
            {t("forward_to_csr")}
          </Button>
        );
      },
    },
    {
      title: <ToolOutlined />,
      width: "40px",
      align: "center",
      render: (ticket) => {
        const content = (
          <>
            {currentTab !== "new_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 1)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="cyan"
              >
                {t("mark_new")}
              </Tag>
            )}
            {currentTab !== "inprogress_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 2)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="green"
              >
                {t("mark_inprogress")}
              </Tag>
            )}
            {currentTab !== "done_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 3)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="blue"
              >
                {t("mark_done")}
              </Tag>
            )}
            {currentTab !== "closed_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 4)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="purple"
              >
                {t("mark_closed")}
              </Tag>
            )}
          </>
        );
        return ticket.manage_by.id === ticket.reseller_agent.id ? (
          <Popover placement="left" content={content} trigger="hover">
            <MoreOutlined />
          </Popover>
        ) : (
          ""
        );
      },
    },
  ];

  const handleUpdateTicketStatus = (id, status) => {
    const formData = new FormData();
    if (status) {
      formData.append("status", status);
    }
    updateTickets(id, formData)
      .then((response) => {
        if (response.data.success === true) {
          // Reload Callback
          reloadCallback();
          // Success
          NotificationWithIcon(
            "success",
            t("ticket_updated"),
            t("ticket_updated_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };
  const forwardTicketToCSR = (id) => {
    forwardTickets(id)
      .then((response) => {
        if (response.data.success === true) {
          // Reload Callback
          reloadCallback();
          // Success
          NotificationWithIcon(
            "success",
            t("ticket_updated"),
            t("ticket_updated_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };

  return (
    <>
      <Table
        columns={TicketsTableColumns}
        dataSource={tickets}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default TicketsTable;

import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

const LevelConfiguration = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data && (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <h3>{t("basic_level")}</h3>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("target")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_target ? data.basic_target : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("retain_rate")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_retain_rate ? data.basic_retain_rate + "%" : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("commission")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_commission ? data.basic_commission + "%" : "-"}
            </div>
          </Col>
          <Col span={24}>
            <h3>{t("pro_level")}</h3>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("target")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_target ? data.pro_target : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("retain_rate")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_retain_rate ? data.pro_retain_rate + "%" : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("commission")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_commission ? data.pro_commission + "%" : "-"}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LevelConfiguration;

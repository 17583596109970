import React, { useContext } from "react";
import { Card, Button, Tag, Row, Col, Tabs, Alert } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import BankDetails from "./BankDetails";
import LevelConfiguration from "./LevelConfiguration";
import {
  RESELLER_STATUS_ACTIVE,
  RESELLER_STATUS_INACTIVE,
  RESELLER_STATUS_KYC,
  RESELLER_STATUS_REJECTED,
  RESELLER_STATUS_REVIEW,
} from "../../../configs/constants";
import ProfileUpdate from "./ProfileUpdate";

const Profile = () => {
  const { authUser } = useContext(AppContext);
  const { t } = useTranslation();

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "profile",
      breadcrumbName: t("menu_profile"),
      icon: <UserOutlined />,
    },
  ];

  const tabItems = [
    {
      label: t("bank_detail"),
      key: "bank-detail",
      children: <BankDetails data={authUser.bank_details} />,
    },
    {
      label: t("level_config"),
      key: "level-config",
      children: <LevelConfiguration data={authUser.level_configuration} />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_profile")}
          ghost={false}
          extra={[]}
        />
      </Card>
      {authUser.status === RESELLER_STATUS_KYC && (
        <Alert
          message={
            t("hi") +
            " " +
            authUser.first_name +
            " " +
            t("thankyou_for_applying_account")
          }
          description={t("account_pending_message")}
          type="info"
          showIcon
          style={{
            marginBottom: "20px",
          }}
        />
      )}
      {authUser.status === RESELLER_STATUS_REJECTED && (
        <Alert
          message={
            t("hi") + " " + authUser.first_name + " " + t("account_rejection")
          }
          description={authUser.rejection_reason}
          type="warning"
          showIcon
          style={{
            marginBottom: "20px",
          }}
        />
      )}
      {authUser.status === RESELLER_STATUS_REVIEW && (
        <Alert
          message={
            t("hi") +
            " " +
            authUser.first_name +
            " " +
            t("account_under_review")
          }
          description={t("account_under_review_details")}
          type="info"
          showIcon
          style={{
            marginBottom: "20px",
          }}
        />
      )}
      {authUser.status === RESELLER_STATUS_INACTIVE && (
        <Alert
          message={
            t("hi") + " " + authUser.first_name + " " + t("account_blocked")
          }
          description={t("kindly_contact_at")}
          type="error"
          showIcon
          style={{
            marginBottom: "20px",
          }}
        />
      )}
      {authUser.status === RESELLER_STATUS_REJECTED ? (
        <Card>
          <ProfileUpdate />
        </Card>
      ) : (
        <Card style={{ marginBottom: "20px" }}>
          <Row gutter={[20, 20]}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("name")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.first_name ? authUser.first_name : "-"}{" "}
                {authUser.last_name && authUser.last_name}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("email")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.email ? authUser.email : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("phone_number")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.phone ? authUser.phone : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("status")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.status === RESELLER_STATUS_ACTIVE ? (
                  <Tag color={"green"}>{t("active")}</Tag>
                ) : authUser.status === RESELLER_STATUS_KYC ? (
                  <Tag color={"blue"}>{t("kyc")}</Tag>
                ) : authUser.status === RESELLER_STATUS_REJECTED ? (
                  <Tag color={"orange"}>{t("rejected")}</Tag>
                ) : authUser.status === RESELLER_STATUS_REVIEW ? (
                  <Tag color={"blue"}>{t("in_review")}</Tag>
                ) : authUser.status === RESELLER_STATUS_INACTIVE ? (
                  <Tag color={"red"}>{t("inactive")}</Tag>
                ) : (
                  "-"
                )}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("type")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.user_type ? authUser.user_type : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("reseller_number")}
              </div>
              <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                {authUser.reseller_number ? authUser.reseller_number : "-"}
              </div>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {t("photo_of_id")}
              </div>
              <Button
                type="primary"
                href={authUser.user_photo_id}
                target="_blank"
              >
                <EyeOutlined />
              </Button>
            </Col>
            {authUser.company_registration_document && (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("company_registration_document")}
                </div>
                <Button
                  type="primary"
                  href={authUser.company_registration_document}
                  target="_blank"
                >
                  <EyeOutlined />
                </Button>
              </Col>
            )}
            {authUser.reseller_company_name && (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("company_name")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {authUser.reseller_company_name}
                </div>
              </Col>
            )}
          </Row>
        </Card>
      )}
      {authUser.status === RESELLER_STATUS_ACTIVE && (
        <Card>
          <Tabs
            defaultActiveKey="bank-detail"
            type="card"
            centered
            items={tabItems}
          />
        </Card>
      )}
    </>
  );
};

export default Profile;

import "../AuthStyle.css";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  userRegister,
  userRegisterValidate,
  sendOTPSMS,
} from "../../../network/network";
import {
  Form,
  Input,
  Button,
  message,
  Col,
  Row,
  Radio,
  Upload,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { USER_TYPE_RESELLER } from "../../../configs/constants";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import ImageUpload from "../../../components/ImageUpload";

const Register = () => {
  const { setAuthUser, setFullPageLoading, appConfig, setAppConfigWrapper } =
    useContext(AppContext);
  const [OTPFormVisible, setOTPFormVisible] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [intervalID, setIntervalID] = useState(false);
  const [loading, setloading] = useState(false);
  const [userType, setUserType] = useState([]);
  const [userIDImage, setUserIDImage] = useState(false);
  const [companyRegistrationDoc, setCompanyRegistrationDoc] = useState(false);
  const [registerForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    // Reset Form Fields
    registerForm.resetFields();
    registerForm.setFieldsValue({
      type: USER_TYPE_RESELLER,
    });
    setUserType([]);
    setUserIDImage(false);
    setCompanyRegistrationDoc(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (remainingTime < 1) {
      clearInterval(intervalID);
    }
  }, [remainingTime, intervalID]);

  const onOTPFormFinish = (otpValues) => {
    registerForm.validateFields().then((values) => {
      // Set Form values as FormData
      const formData = new window.FormData();
      if (otpValues) {
        formData.append("otp", otpValues.otp);
      }
      if (typeof userIDImage == "object" && userIDImage) {
        formData.append("user_photo_id", userIDImage);
      }
      if (
        typeof companyRegistrationDoc == "object" &&
        companyRegistrationDoc &&
        userType !== "individual"
      ) {
        formData.append(
          "company_registration_document",
          companyRegistrationDoc
        );
      }
      Object.keys(values).map((key) => {
        formData.append(key, values[key]);
        return true;
      });
      setloading(true);
      userRegister(formData)
        .then((response) => {
          if (response.data && response.data.success) {
            setFullPageLoading(true);
            localStorage.setItem(
              "access_token",
              response.data.data.user.access_token
            );
            setAuthUser(response.data.data.user);
            setFullPageLoading(false);
            window.location.reload();
          } else {
            let error = response.response;
            console.log(error);
            if (error.status === 422) {
              message.error(error.data.message);
            } else if (error.status === 500) {
              let errorMessages = Object.values(error.data.data.errors);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            } else if (
              error.status === 400 &&
              error.data.message === "OTP validation Failed."
            ) {
              NotificationWithIcon(
                "error",
                "OTP validation Failed, Try Again."
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setloading(false);
        });
    });
  };

  const startTimer = () => {
    setRemainingTime(120);
    let interval = setInterval(() => {
      setRemainingTime((remainingTime) => remainingTime - 1);
    }, 1000);
    setIntervalID(interval);
  };

  const handleSendOTPSMS = () => {
    let mobileNumber = registerForm.getFieldValue("phone");
    sendOTPSMS({ number: mobileNumber }).then((response) => {
      startTimer();
      setOTPFormVisible(true);
    });
  };

  const handleRegisterValidation = () => {
    if (!companyRegistrationDoc && userType === "company") {
      message.error(t("validate_company_registration_document_required"));
    } else if (!userIDImage) {
      message.error(t("validate_photo_of_id_required"));
    } else {
      registerForm.validateFields().then((values) => {
        setloading(true);
        // Sending Request to API
        userRegisterValidate(values) // OTP SMS sent as well
          .then((response) => {
            setloading(false);
            if (response.data && response.data.success) {
              startTimer();
              setOTPFormVisible(true);
            } else {
              let error = response.response;
              if (error.status === 400) {
                let errorMessages = Object.values(error.data.data.errors);
                NotificationWithIcon(
                  "error",
                  "Validation Error",
                  errorMessages[0][0]
                );
              } else if (error.status === 500) {
                let errorMessages = Object.values(error.data.data.errors);
                errorMessages.forEach(function (key, value) {
                  let temp = Object.values(key);
                  message.error(temp[0]);
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  function beforeUpload(file) {
    const is5MB = file.size / 1024 / 1024 < 5;
    if (!is5MB) {
      message.error(t("validate_file_size_5"));
    }
    return is5MB;
  }

  return (
    <>
      <Spin spinning={loading}>
        <div className="authSection">
          <div className="authInnerSection">
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="authSection-right"
                style={{ borderRadius: "20px", backgroundColor: "#ffffff" }}
              >
                <div className="lang_button">
                  <Radio.Group
                    value={appConfig.direction}
                    onChange={(e) => {
                      setAppConfigWrapper({ direction: e.target.value });
                    }}
                    buttonStyle="solid"
                    defaultValue="ltr"
                  >
                    <Radio.Button value="ltr">English</Radio.Button>
                    <Radio.Button value="rtl">عربي</Radio.Button>
                  </Radio.Group>
                </div>
                <div
                  style={{
                    padding: "20px",
                    Width: "100%",
                    margin: "20px auto",
                  }}
                >
                  <Form
                    name="register-user-form"
                    layout="vertical"
                    onFinish={handleRegisterValidation}
                    form={registerForm}
                    style={{
                      display: !OTPFormVisible ? "block" : "none",
                    }}
                  >
                    <h2 className="authSection-heading">{t("sign_up")}</h2>
                    <Row gutter={[20, 10]}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="first_name"
                          label={t("first_name")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_first_name_required"),
                              whitespace: true,
                            },
                            {
                              min: 3,
                              message: t("validate_first_name_min_characters"),
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="last_name"
                          label={t("last_name")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_last_name_required"),
                              whitespace: true,
                            },
                            {
                              min: 3,
                              message: t("validate_last_name_min_characters"),
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="phone"
                          label={t("phone_number") + " eg: +966 5xxxxxxx"}
                          rules={[
                            {
                              required: true,
                              message: t("validate_phone_required"),
                            },
                            {
                              min: 12,
                              message: t("validate_phone_exact_limit"),
                            },
                            {
                              max: 12,
                              message: t("validate_phone_exact_limit"),
                            },
                          ]}
                          style={{ textAlign: "left" }}
                          hasFeedback
                        >
                          <PhoneInput
                            localization={["sa"]}
                            masks={{ sa: "(...) ..-..-.." }}
                            placeholder="+966 (123) 45-67-89"
                            country={"sa"}
                            onlyCountries={["sa"]}
                            inputStyle={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="email"
                          label={t("email")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_email_required"),
                            },
                            {
                              type: "email",
                              message: t("validate_email_invalid"),
                            },
                          ]}
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="password"
                          label={t("password")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_password_required"),
                            },
                            {
                              min: 6,
                              message: t("validate_password_min_characters"),
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="password_confirmation"
                          label={t("confirm_password")}
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: t(
                                "validate_password_confirm_not_matched"
                              ),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    t("validate_password_confirm_not_matched")
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>

                      <Form.Item name="type" style={{ display: "none" }}>
                        <input type="hidden" />
                      </Form.Item>

                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="user_type"
                          label={t("type")}
                          rules={[
                            {
                              required: true,
                              message: t("validate_type_required"),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              setUserType(e.target.value);
                            }}
                          >
                            <Radio value="individual">{t("individual")}</Radio>
                            <Radio value="company">{t("company")}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {(userType === "individual" ||
                        userType === "company") && (
                        <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={5}>
                          <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                            <div className="ant-form-item-label">
                              <label
                                className="ant-form-item-required"
                                title={t("photo_of_id")}
                              >
                                {t("photo_of_id")}
                              </label>
                            </div>
                            <ImageUpload
                              name="image"
                              callbackSetImageFile={setUserIDImage}
                              image={userIDImage}
                            />
                          </div>
                        </Col>
                      )}
                      {userType === "company" && (
                        <>
                          <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={7}>
                            <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                              <div className="ant-form-item-label">
                                <label
                                  className="ant-form-item-required"
                                  title={t("company_registration_document")}
                                >
                                  {t("company_registration_document")}
                                </label>
                              </div>
                              <Upload.Dragger
                                maxCount={1}
                                showUploadList={false}
                                accept=".pdf"
                                beforeUpload={beforeUpload}
                                customRequest={(info) => {
                                  setCompanyRegistrationDoc(info.file);
                                }}
                              >
                                {companyRegistrationDoc ? (
                                  <div>
                                    <UploadOutlined />
                                    <div style={{ marginTop: 8 }}>
                                      {t("change")}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>
                                      {t("upload")}
                                    </div>
                                  </div>
                                )}
                                {companyRegistrationDoc?.name}
                              </Upload.Dragger>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                              name="reseller_company_name"
                              label={t("company_name")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validate_company_name_required"),
                                  whitespace: true,
                                },
                              ]}
                              hasFeedback
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                    <center>
                      <Form.Item style={{ margin: "20px 0" }}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          style={{ maxWidth: "300px" }}
                        >
                          {t("sign_up")}
                        </Button>
                      </Form.Item>
                    </center>
                  </Form>
                  <Form
                    name="validate-otp-form"
                    layout="vertical"
                    onFinish={onOTPFormFinish}
                    style={{
                      width: "300px",
                      margin: "20px auto 0",
                      display: OTPFormVisible ? "block" : "none",
                    }}
                  >
                    <p>
                      {t("opt_message")}
                      <br />
                      {t("if_you_want_to_change_phone_number")}{" "}
                      <Link to="#" onClick={() => setOTPFormVisible(false)}>
                        {t("click_here")}
                      </Link>
                    </p>
                    <Form.Item
                      name="otp"
                      label="OTP"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          max: 4,
                        },
                        {
                          min: 4,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input type="number" minLength={4} maxLength={4} />
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Form.Item>
                        <Button type="primary" size="large" htmlType="submit">
                          {t("validate")}
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="link"
                          disabled={remainingTime < 1 ? false : true}
                          onClick={handleSendOTPSMS}
                          style={{ padding: "0" }}
                        >
                          {t("resend_code_in")}
                          <span
                            style={{
                              margin: "0 10px",
                              display:
                                remainingTime < 1 ? "none" : "inline-block",
                            }}
                          >
                            {Math.floor(remainingTime / 60)
                              .toString()
                              .padStart(2, "0") +
                              ":" +
                              Math.floor(remainingTime % 60)
                                .toString()
                                .padStart(2, "0")}
                          </span>
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                  <center>
                    {t("already_have_an_account")}{" "}
                    <Link to="/login">{t("login")}</Link>
                  </center>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Register;

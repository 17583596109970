import React from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter } from "../../../utils";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const PayoutHistory = ({ data }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const tableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("amount"),
      render: (payout) => {
        return <>{currencyFormatter.format(payout.amount)}</>;
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      render: (date) => {
        return date ? moment(date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("account_number"),
      dataIndex: "account_number",
    },
    {
      title: t("reference_number"),
      dataIndex: "reference_number",
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                navigate("/commission/payout_history/" + record.id);
              }}
              title={t("preview")}
              style={{
                border: "1px solid",
                padding: "8px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 5px",
              }}
            >
              <EyeOutlined twoToneColor="#1890ff" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={data}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default PayoutHistory;

import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const BankDetails = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data && (
        <Row gutter={[20, 20]}>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("account_title")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.account_title ? data.account_title : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("bank_name")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.bank_name ? data.bank_name : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("account_number")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.account_number ? data.account_number : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("branch_code")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.branch_code ? data.branch_code : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("city")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.city ? data.city : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("country")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.country ? data.country : "-"}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BankDetails;

const trans_ar_EG = {
  // Form Fields
  name: "الأسم",
  first_name: "الاسم الأول",
  last_name: "الاسم الأخير",
  name_ar: "الاسم العربي",
  name_en: "الاسم الانجليزي",
  price: "السعر",
  email: "البريد الالكتروني",
  password: "الرقم السري",
  confirm_password: "تاكيد الرقم السري",
  phone_number: "رقم الهاتف",
  business_name: "أسم المنشأة",
  business_type: "نشاط المنشأة",
  shop_address: "عنوان المنشأة",
  cr_number: "رقم السجل التجاري/ وثيقة العمل الحر",
  vat_number: "رقم ضريبة القيمة المضافة",
  business_logo: "شعار المنشأة",
  image: "صورة",
  is_taxable: "خاضع للضريبه",
  is_refunded: "يتم استرداد المبلغ",
  is_QRproduct: "هو منتج QR",
  is_stockable: "قابل للتخزين",
  reset: "إعادة الضبط",
  cart: "عربة التسوق",
  address: "العنوان",
  company: "شركة",
  code: "رمز",
  branch: "الفرع",
  active: "نشيط",
  inactive: "غير نشط",
  kyc: "KYC",
  in_review: "في مراجعة",

  no_of_employees: "عدد الموظفين",
  no_of_licenses: "عدد التراخيص",
  vat_certificate: "شهادة ضريبة القيمة المضافة",
  cr_certificate: "شهادة السجل التجاري",
  change: "يتغير",
  preview: "معاينة",
  billing_address: "العنوان وصول الفواتير",
  billing_city: "المدينة",
  billing_state: "المنطقة",
  billing_country: "الدولة",
  billing_post_code: "الرمز البريدي",
  no_attachment: "لا يوجد مرفق",

  // Form Validations
  validate_email_required: "حقل البريد الالكتروني مطلوب.",
  validate_email_invalid: "البريد لإلكتروني غير صحيح.",
  validate_password_required: "حقل كلمة المرور مطلوب",
  validate_password_min_characters: "يجب أن لا تقل كلمة المرور عن 6 أحرف.",
  validate_password_confirm_not_matched: "تأكيد كلمة المرور غير متطابقه",
  validate_name_required: "حقل الأسم مطلوب.",
  validate_first_name_required: "مطلوب حقل الاسم الأول.",
  validate_first_name_min_characters: "يجب ألا يقل الاسم الأول عن 3 أحرف.",
  validate_last_name_required: "حقل الاسم الأخير مطلوب.",
  validate_last_name_min_characters: "يجب ألا يقل اسم العائلة عن 3 أحرف.",
  validate_phone_required: "حقل رقم الهاتف مطلوب",
  validate_phone_exact_limit: "يجب أن يكون رقم الهاتف 12 رقمًا بالضبط.",
  validate_image_file_type: "يمكنك فقط تحميل ملف JPG / PNG!",
  validate_image_size_2: "يجب أن يكون حجم الصورة أصغر من 2 ميغا بايت!",
  validate_file_size_5: "يجب أن يكون حجم الملف أصغر من 5 ميغابايت!",
  validate_address_required: "يرجى إدخال عنوان المتجر!",

  // Login
  dont_have_account: "لا يوجد لديك حساب؟",
  login: "تسجيل الدخول",
  visit_website: "زيارة الموقع",

  // Register
  already_have_an_account: "لديك حساب بالفعل؟",
  sign_up: "التسجيل",

  // Forgot
  forgot: "نسيت الرقم السري",
  forgot_password: "هل نسيت الرقم السري؟",
  send_otp: "أرسل كلمة المرور لمرة واحدة",
  verify_otp: "التحقق من كلمة المرور",
  reset_password: "إعادة تعيين الرقم السري",
  reset_password_successfully: "تم إعادة تعيين الرقم السري بنجاح!",

  // otp form
  opt_message:
    "تم إرسال رسالة مع رمز OTP على رقم هاتفك المحمول. يرجى ملء الحقل أدناه والتحقق من صحة.",
  if_you_want_to_change_phone_number: "إذا كنت تريد تغيير رقم الهاتف",
  click_here: "انقر هنا.",
  resend_code_in: "أعد إرسال الرمز في",
  validate: "تحقق",
  allow_round_off: "اسمح بالتقريب إلى رقمين",
  preferred_contact_time: "وقت الاتصال المفضل",

  // Menus
  menu_home: "الصفحة الرئيسية",
  menu_companies: "شركات",
  menu_help_desk: "مكتب المساعدة",
  menu_profile: "Profile",
  menu_commission: "عمولة",
  menu_payout_detail: "بيانات الدفع",

  // Branches
  branch_add: "اضافة فرع",
  branch_added: "تمت إضافة الفرع",
  branch_added_successfully: "تمت إضافة الفرع بنجاح!",
  branch_deleted: "تم حذف الفرع",
  branch_deleted_successfully: "تم حذف الفرع بنجاح!",
  branch_edit: "تحرير الفرع",
  branch_updated: "فرع محدث",
  branch_updated_successfully: "تم تحديث الفرع بنجاح!",

  // invoices
  model: "موديل",
  imei: "IMEI",
  serial_no: "الرقم التسلسلي.",
  amount: "المبلغ",
  due_amount: "مبلغ مستحق",
  installments: "أقساط",
  warranty_from_to: "الضمان من - إلى",

  // Table Columns
  col_product: "المنتج",
  col_price: "سعر",
  col_category: "فئة",
  col_unit: "وحدة",
  col_barcode: "الرمز الشريطي",
  col_action: "إجراء",
  col_order_id: "معرف فريد",
  col_amount: "المبلغ",
  col_tax: "الضريبة",
  type: "الدفع",
  col_quantity: "الكمية",
  status: "الحالة",
  col_created_at: "أنشئت في",
  col_created_by: "انشأ من قبل",
  col_subtotal: "المجموع الفرعي",
  col_sold_quantities: "الكميات المباعة",
  col_gross_sales: "إجمالي المبيعات",
  col_refund_quantities: "الكميات الاسترداد",
  col_gross_refund: "إجمالي المبالغ المعادة",
  col_start_date: "تاريخ البدء",
  col_end_date: "تاريخ الانتهاء",
  date: "تاريخ",
  col_subscription_plan: "خطة الاشتراك",
  col_transaction_id: "رقم الفاتورة",
  col_discount_percentage: "نسبة الخصم",
  col_branches: "الفروع",
  invoice_ID: "مرجع الفاتورة",
  subscription: "الاشتراك",
  license: "رخصة",
  total_amount_charged: "المبلغ الإجمالي",
  col_items: "العناصر",
  col_user: "المستخدم",
  col_payment: "دفع",
  col_new: "تذاكر جديدة",
  col_inprogress: "التذاكر تحت الاجراء",
  col_done: "التذاكر المكتملة",
  col_closed: "التذاكر مغلقة",
  col_description: "وصف",
  col_attachment: "المرفق",
  col_allow_refund: "السماح برد الأموال",
  col_allow_add_products: "السماح بإضافة منتجات",
  col_allow_edit_products: "السماح بتحرير المنتجات",
  col_odoo_status: "حالة Odoo",
  col_source: "مصدر",
  col_pending_orders: "الأوامر المعلقة",
  col_inprogress_orders: "أوامر التقدم",
  col_completed_orders: "الطلبات المكتملة",
  col_cancelled_orders: "الطلبات الملغاة",
  col_branch_from: "فرع من",
  col_branch_to: "فرع ل",
  col_customer_name: "اسم العميل",
  col_contact: "Contact",
  assigned_to: "Assigned To",

  // Warnings
  warning_delete: "هل أنت متأكد من حذف هذا الكيان؟",

  // Miscellaneous
  yes: "نعم",
  no: "لا",
  logout: "تسجيل الخروج",
  cancel: "إلغاء",
  add: "يضيف",
  edit: "يحرر",
  update: "تحديث",
  submit: "يُقدِّم",
  search: "بحث",
  upload: "تحميل",
  back: "عودة",
  order_id: "معرف الطلب",
  order_details: "تفاصيل الطلب",
  issued_at: "تم إصداره في",
  date_range: "النطاق التواريخ",
  delete_entity: "حذف الكيان",
  something_went_wrong: "حدث خطأ ما",
  refund: "إعادة المبلغ",
  user_can_edit_price_on_pos: "يمكن للمستخدم تعديل السعر على نقاط البيع",
  user_can_apply_discount: "يمكن للمستخدم السماح بالخصم",
  user_see_transactions: "يمكن للمستخدم رؤية المعاملات",
  user_can_refund_transaction: "يمكن للمستخدم السماح باسترداد الفواتير",
  user_can_add_products: "يمكن للمستخدم السماح بإضافة منتجات",
  user_can_edit_products: "يمكن للمستخدم السماح بتحرير المنتجات",
  user_can_request_stock_transfer: "يمكن للمستخدم السماح بطلب نقل المخزون",
  user_can_approve_stock_transfer:
    "يمكن للمستخدم السماح بالموافقة على نقل المخزون",
  is_machine_user: "مستخدم الجهاز",
  proceed: "تقدم",
  checkout: "الدفع",
  payment_type: "نوع الدفع",
  cash_collected: "المبلغ المستلم",
  reference_no: "رقم المرجع",
  collected_by_cash: "تم تحصيله نقدًا",
  collected_by_card: "من البطاقة",
  charged_amount: "المبلغ الإجمالي",
  including_vat: "ضريبة القيمة المضافة",
  ask_more_cash: "اطلب المزيد من المال قبل المتابعة!",
  return_back_amount: "المتبقي",
  collect_more_cash: "جمع المزيد من المال",
  print: "طباعة",
  declaration: "تصريح",
  declaration_text:
    "أقر بموجبه أن المعلومات الواردة في هذا النموذج حقيقة وصحيحة وكاملة. وأؤكد مسؤليتي في حال العثور على أي معلومات غير صحيحة و / أو غير كاملة تؤدي إلى انتهاك اللوائح ، وقد تشرع في اتخاذ إجراءات قانونية ، وأوافق على أنني الطرف المسؤول عن ذلك وعن وجميع التهم والعقوبات والانتهاكات.",
  upgrade: "قم بالترقية",
  upgrade_subscription: "ترقية الاشتراك",
  payment_successful: "تم الدفع بنجاح!",
  your_payment_successfully: "تم الدفع الخاص بك بنجاح.",
  payment_failed: "عملية الدفع فشلت!",
  try_again_payment_failed:
    "لقد فشلت عملية الدفع الخاصة بك. من فضلك حاول مرة اخرى.",
  all: "الجميع",
  cart_empty: "عربة التسوق فارغة",
  total_amount: "المبلغ الإجمالي",
  licenses: "الترخيص (التراخيص)",
  purchase: "شراء",
  total_taxes: "إجمالي الضرائب",
  credit_card: "بطاقة ائتمان",
  stc_pay: "STC دفع",
  mada: "مدى",
  cash: "نقدي",
  card: "بطاقة",
  multi_payment: "متعدد الدفع",
  plz_fill_details: "يرجى ملء التفاصيل أدناه ، للمضي قدمًا",
  billing_details: "تفاصيل الفاتورة",
  active_subscription: "اشتراك نشط",
  available_upgrades: "الترقيات المتاحة",
  already_requested: "تم الطلب بالفعل",
  request_upgrade: "طلب ترقية",
  request_subscription_upgrade: "طلب ترقية الاشتراك",
  pro_plan: "الخطة احترافية",
  basic_plan: "الخطة الأساسية",
  discount: "تخفيض",
  select_a_discount: "حدد الخصم",
  total_amount_payable: "المبلغ الإجمالي المستحق",
  unpaid_invoice: "فاتورة غير مدفوعة!",
  pay_your_invoice: "يرجى دفع فاتورتك غير المسددة",
  show_invoices: "إظهار الفواتير",
  what_type_of_refund: "ما نوع الاسترداد الذي تريد القيام به؟",
  full: "كامل",
  partial: "جزئي",
  refund_amount: "المبلغ المسترد",
  qty: "الكمية",
  year: "سنة",
  month: "شهر",
  day: "يوم",
  additional: "إضافي",
  users: "المستخدمون",
  pay_on: "ادفع على",
  action: "إجراء",
  pay_now: "ادفع الآن",
  view_invoice: "عرض الفاتورة",
  one_time_payment: "الدفع لمرة واحدة",
  months_plan: " خطة الأشهر",
  complete: "كامل",
  incomplete: "ناقص",

  is_vat_exempt: "لست خاضع لضريبة القيمة المضافة",

  connection: "اتصال",
  connected: "متصل",
  disconnected: "انقطع الاتصال",

  skip: "تخطى",

  kindly_complete_the_store_details_within:
    "يرجى استكمال تفاصيل المتجر في الداخل",
  days_otherwise_your_account_will_be_blocked: "أيام وإلا سيتم حظر حسابك.",
  account_freezed: "تم تجميد حسابك",
  upgrade_subscription_unfreeze: "يرجى اختيار ترقية الاشتراك لالغاء التجميد",
  pay_generated_invoice_first: "ادفع الفاتورة المنشاة اولا",
  you_selected_following_package: "لقد اخترت الباقة التالية",
  unlimited: "غير محدود",
  per_user: "لكل مستخدم",
  payment_modal: "طريقة الدفع",
  select_payment_method: "اختر طريقة الدفع",
  service_name: "اسم الخدمة",
  subscriptions_history: "سجل الاشتراكات",
  spending_history: "تاريخ الإنفاق",
  trial_days: "الايام التجريبية",
  paid: "مدفوع",
  unpaid: "غير مدفوع",
  qr: "رمز الاستجابة السريعة",
  dining_table: "طاولة الطعام",
  note: "ملحوظة",
  clear: "واضح",
  selected_item: "العنصر المحدد",
  pending: "قيد الانتظار",
  completed: "مكتمل",
  cancelled: "ألغيت",
  rejected: "مرفوض",
  available_stock: "المخزون المتوفر",
  requested_stock: "الأسهم المطلوبة",
  current_stock: "المخزون الحالي",
  your_branch: "فرعك",
  approved_quantity: "الكمية المعتمدة",
  requested_quantity: "الكمية المطلوبة",
  insufficient_quantity: "كمية غير كافية",
  out_of_stock: "إنتهى من المخزن",
  product_already_selected: "المنتج محدد بالفعل",
  reseller_number: "رقم الموزع",

  hi: "مرحبا",
  thankyou_for_applying_account: "شكرا لك على التقدم بطلب للحصول على حساب!",
  account_pending_message: "حسابك في انتظار موافقة مسؤول الموقع حاليًا.",
  account_rejection: "للأسف، تم رفض حسابك!",
  account_under_review: "حسابك قيد المراجعة!",
  account_under_review_details:
    "وعادة ما يستغرق منا حوالي يوم واحد. وإلى أن تكتمل المراجعة، لن تتمكن من فعل أي شيء",
  account_blocked: "حسابك محظور!",
  kindly_contact_at: "يرجى التواصل معنا على contact@anypos.app",

  basic_level: "مستوى أساسي",
  pro_level: "المستوى المهني",
};

export default trans_ar_EG;

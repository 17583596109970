import React, { useContext } from "react";
import { Card, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../utils";
import { AppContext } from "../../../context/AppContextProvider";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import PayoutHistory from "./PayoutHistory";
import BalanceDetails from "./BalanceDetails";

const Commission = () => {
  const { authUser } = useContext(AppContext);
  const { t } = useTranslation();

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "commission",
      breadcrumbName: t("menu_commission"),
      icon: <UserOutlined />,
    },
  ];

  const tabItems = [
    {
      label: t("wallet_detail"),
      key: "wallet-detail",
      children: (
        <div>
          {t("available_balance")} <br />
          <h2>{currencyFormatter.format(authUser.reseller_balance)}</h2>
        </div>
      ),
    },
    {
      label: t("balance_details"),
      key: "balance-details",
      children: <BalanceDetails data={authUser.contributing_companies} />,
    },
    {
      label: t("payout_history"),
      key: "payout-history",
      children: <PayoutHistory data={authUser.payout_history} />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_commission")}
          ghost={false}
          extra={[]}
        />
      </Card>

      <Card>
        <Tabs
          defaultActiveKey="wallet-detail"
          type="card"
          centered
          items={tabItems}
        />
      </Card>
    </>
  );
};

export default Commission;

import "./Home.css";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { AppContext } from "../../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import CustomImageBox from "../../../components/CustomImageBox";
import { getDashboardSummary } from "../../../network/network";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { authUser, appConfig, setActiveMenu } = useContext(AppContext);
  const [dashboardData, setDashboardData] = useState([]);
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    getDashboardSummary().then((response) => {
      if (response.data.success === true) {
        setDashboardData(response.data.data);
      }
    });
  }, []);

  return (
    <>
      <Card className="fade-in-from-left" style={{ marginBottom: 20 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 style={{ direction: appConfig.direction, margin: 0 }}>
            {appConfig.direction === "ltr"
              ? "Hi " + authUser.name + " 👋, Welcome to AnyPOS!"
              : "مرحبا " + authUser.name + " 👋 اهلا بك في AnyPOS!"}
          </h3>
          <Button
            target="_blank"
            href={
              "https://qa-portal.anypos.app/register?reseller_number=" +
              authUser.reseller_number
            }
            type="primary"
          >
            {t("add_company")}
          </Button>
        </div>
      </Card>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <CustomImageBox
            image="images/dashboard/rank.png"
            title="Partner Rank"
            value={dashboardData.reseller_level}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <CustomImageBox
            image="images/dashboard/unpaid-comission.png"
            title="Unpaid Comission"
            value={dashboardData.trial_customers_count}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <CustomImageBox
            image="images/dashboard/paid-comission.png"
            title="Paid Comission"
            value={dashboardData.paid_customers_count}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/active-customers.png"
              title="Active Customers"
              value={dashboardData.active_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=99",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/inactive-customers.png"
              title="Inactive Customers"
              value={dashboardData.inactive_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?monthly_new=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/monthly-new-customers.png"
              title="Monthly New Customers"
              value={dashboardData.monthly_new_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?monthly_inactive=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/monthly-inactive-customers.png"
              title="Monthly Inactive Customers"
              value={dashboardData.monthly_inactive_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=2",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/submiting-kyc.png"
              title="Submitting KYC"
              value={dashboardData.submitting_kyc_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?idle=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/idle.png"
              title="Idle Customers"
              value={dashboardData.idle_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=new_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/new-tickets.png"
              title="New Tickets"
              value={dashboardData.new_tickets_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=inprogress_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/in-progress-tickets.png"
              title="In Progress Tickets"
              value={dashboardData.in_progress_tickets_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=done_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/done-tickets.png"
              title="Done Tickets"
              value={dashboardData.done_tickets_count}
            />{" "}
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=closed_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/closed-tickets.png"
              title="Closed Tickets"
              value={dashboardData.closed_tickets_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=late_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/late-tickets.png"
              title="Late Tickets"
              value={dashboardData.late_tickets_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("helpdesk");
              navigate({
                pathname: "/helpdesk",
                search: "?status=delayed_tickets&page=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/delayed-tickets.png"
              title="Delayed Tickets"
              value={dashboardData.delayed_tickets_count}
            />
          </div>
        </Col>
        {/*   <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("subscriptions");
              navigate({
                pathname: "/subscriptions",
                search: "?d_sub_near_expiry=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/subscriptions-near-expiry.png"
              title="Subscriptions Near Expiry"
              value={dashboardData.sub_near_expiry_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=3",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/under-review-companies.png"
              title="Under Review Companies"
              value={dashboardData.under_review_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=6",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/dashboard/unpaid-invoices.png"
              title="Unpaid Invoices"
              value={dashboardData.unpaid_invoices_count}
            />
          </div>
        </Col>
        
       
        */}
      </Row>
    </>
  );
};

export default Home;

import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Content/Home/Home";
import Companies from "../pages/Content/Companies/Companies";
import Helpdesk from "../pages/Content/Helpdesk/Helpdesk";
import {
  AppstoreOutlined,
  CustomerServiceOutlined,
  HomeOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { AppContext } from "../context/AppContextProvider";
import { filterPagesByAddons } from "../utils.js";
import Profile from "../pages/Content/Profile/Profile.js";
import PayoutDetails from "../pages/Content/Commission/PayoutDetails.js";
import Commission from "../pages/Content/Commission/Commission.js";

export const ContentPages = [
  {
    path: "/",
    name: "home",
    text: "menu_home",
    component: Home,
    icon: HomeOutlined,
    showInSider: true,
  },
  {
    path: "/companies",
    name: "companies",
    text: "menu_companies",
    component: Companies,
    icon: AppstoreOutlined,
    showInSider: true,
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    text: "menu_help_desk",
    component: Helpdesk,
    icon: CustomerServiceOutlined,
    showInSider: true,
  },
  {
    path: "/profile",
    name: "profile",
    text: "menu_profile",
    component: Profile,
    icon: AppstoreOutlined,
    showInSider: true,
  },
  {
    path: "/commission/payout_history/:payoutId",
    name: "payout_detail",
    text: "menu_payout_detail",
    component: PayoutDetails,
    icon: false,
    showInSider: false,
  },
  {
    path: "/commission",
    name: "commission",
    text: "menu_commission",
    component: Commission,
    icon: WalletOutlined,
    showInSider: true,
  },
];

const AppRoutes = () => {
  const { authUser } = useContext(AppContext);

  return (
    <>
      <Routes>
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Home />} />

        {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
          if (page.component) {
            return (
              <Route
                exact
                path={page.path}
                name={page.name}
                element={<page.component />}
                key={page.name}
              />
            );
          } else {
            let subRoutes =
              page.subMenus &&
              filterPagesByAddons(page.subMenus, authUser).map(function (
                subPage,
                i
              ) {
                if (subPage.component) {
                  return (
                    <Route
                      exact
                      path={subPage.path}
                      name={subPage.name}
                      element={<subPage.component />}
                      key={subPage.name}
                    />
                  );
                }
                return false;
              });
            return subRoutes;
          }
        })}
      </Routes>
    </>
  );
};

export default AppRoutes;

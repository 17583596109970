// Plan Types
export const PLAN_TYPE_BASIC = 1;
export const PLAN_TYPE_PRO = 2;
export const PLAN_TYPE_DAILY = 3;

// Plan Monthly
export const PERIOD_MONTHLY = 1;
export const PERIOD_YEARLY = 2;
export const PERIOD_DAILY = 3;

// User Types
export const USER_TYPE_BUSINESS_OWNER = 2; // store owner
export const USER_TYPE_ADMIN_STAFF = 4; // staff
export const USER_TYPE_SUPER_ADMIN = 5; // super admin
export const USER_TYPE_REFERRAL = 6;
export const USER_TYPE_RESELLER = 7;

// Company Status
export const COMPANY_STATUS_ACTIVE = 1;
export const COMPANY_STATUS_KYC = 2;
export const COMPANY_STATUS_REVIEW = 3;
export const COMPANY_STATUS_SUBSCRIPTION_ENDED = 4;
export const COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW = 5;
export const COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED = 6;
export const COMPANY_STATUS_BLOCKED = 99;

// Invoice Status
export const INVOICE_STATUS_UNPAID = 0;
export const INVOICE_STATUS_CANCELLED = 1;
export const INVOICE_STATUS_PAID = 2;
export const INVOICE_STATUS_REFUNDED = 3;

// Invoice Detail Type
export const INVOICE_DETAIL_TYPE_SUBSCRIPTION = 1;
export const INVOICE_DETAIL_TYPE_LICENSE = 2;
export const INVOICE_DETAIL_TYPE_DISCOUNT = 3;
export const INVOICE_DETAIL_TYPE_TAX = 4;
export const INVOICE_DETAIL_TYPE_DEVICE_PAYMENT = 5;
export const INVOICE_DETAIL_TYPE_TOPUP = 7;

// Menu Keys
export const SIDEBAR_MENU_KEY_HOME = "home";
export const SIDEBAR_MENU_KEY_PROFILE = "profile";

export const companyStatus = [
  { id: 1, text: "Active" },
  { id: 2, text: "Submitting KYC" },
  { id: 3, text: "In Review" },
  { id: 4, text: "Subscription Ended" },
  { id: 5, text: "Subscription In Review" },
  { id: 6, text: "Invoice Generated" },
  { id: 99, text: "Blocked / Inactive" },
];

// Reseller status
export const RESELLER_STATUS_ACTIVE = 1;
export const RESELLER_STATUS_KYC = 2;
export const RESELLER_STATUS_REJECTED = 3;
export const RESELLER_STATUS_REVIEW = 4;
export const RESELLER_STATUS_INACTIVE = 99;

import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Tag,
  Pagination,
  Form,
  Input,
  Select,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, AppstoreOutlined, EyeOutlined } from "@ant-design/icons";
import { getCompanies } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  COMPANY_STATUS_ACTIVE,
  COMPANY_STATUS_KYC,
  COMPANY_STATUS_REVIEW,
  COMPANY_STATUS_SUBSCRIPTION_ENDED,
  // COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW,
  COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
  COMPANY_STATUS_BLOCKED,
  companyStatus,
} from "../../../configs/constants";
import { useNavigate, useLocation } from "react-router-dom";

const Companies = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlPage = parseInt(query.get("page")) || 1;
  const urlStatusValue = parseInt(query.get("d_status"));
  const urlMonthlyNewValue = parseInt(query.get("monthly_new"));
  const urlMonthlyInactiveValue = parseInt(query.get("monthly_inactive"));
  const urlFailedOdooAccountsValue = parseInt(
    query.get("failed_odoo_accounts")
  );
  const urlIdleValue = parseInt(query.get("idle"));
  const urlReferralCode = query.get("referral_code");

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [companiesTableData, setCompaniesTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(urlPage);
  const [searchParams, setSearchParams] = useState([]);
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    const params = new URLSearchParams();

    searchParams.company_name &&
      params.append("company_name", searchParams.company_name);
    searchParams.CR_number &&
      params.append("CR_number", searchParams.CR_number);
    searchParams.phone && params.append("phone", searchParams.phone);
    searchParams.status
      ? searchParams.status && params.append("status", searchParams.status)
      : urlStatusValue && params.append("status", urlStatusValue);
    urlMonthlyNewValue && params.append("monthly_new", urlMonthlyNewValue);
    urlMonthlyInactiveValue &&
      params.append("monthly_inactive", urlMonthlyInactiveValue);
    urlFailedOdooAccountsValue &&
      params.append("failed_odoo_accounts", urlFailedOdooAccountsValue);
    urlIdleValue && params.append("idle", urlIdleValue);
    urlReferralCode && params.append("referral_code", urlReferralCode);

    if (paginationPage > 0) {
      params.append("page", paginationPage);
    }
    if (params.size > 0) {
      navigate({
        pathname: "/companies",
        search: "?" + params.toString(),
      });
    }
    if (urlStatusValue) {
      searchFormRef.setFieldsValue({
        status: urlStatusValue,
      });
      getCompanies({
        ...searchParams,
        status: urlStatusValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else if (urlMonthlyNewValue) {
      getCompanies({
        ...searchParams,
        monthly_new: urlMonthlyNewValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else if (urlMonthlyInactiveValue) {
      getCompanies({
        ...searchParams,
        monthly_inactive: urlMonthlyInactiveValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else if (urlFailedOdooAccountsValue) {
      getCompanies({
        ...searchParams,
        failed_odoo_accounts: urlFailedOdooAccountsValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else if (urlIdleValue) {
      getCompanies({
        ...searchParams,
        idle: urlIdleValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else if (urlReferralCode) {
      getCompanies({
        ...searchParams,
        referral_code: urlReferralCode,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setCompaniesTableData(response.data.data.companies);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else {
      getCompanies({
        ...searchParams,
        page: paginationPage,
      })
        .then((response) => {
          setCompaniesTableData(response.data.data.companies);
          setPaginationData(response.data.data.pagination);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [
    searchParams,
    paginationPage,
    searchFormRef,
    navigate,
    urlStatusValue,
    urlMonthlyNewValue,
    urlMonthlyInactiveValue,
    urlFailedOdooAccountsValue,
    urlIdleValue,
    urlReferralCode,
  ]);

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  // const handlePagination = useCallback(
  //   (page) => {
  //     setPaginationPage(page);
  //     // const params = new URLSearchParams();
  //     // if (urlCname) {
  //     //   params.append("company", urlCname);
  //     // }
  //     // params.append("page", page);
  //     // navigate({
  //     //   pathname: "/companies",
  //     //   search: params.toString(),
  //     // });
  //   },
  //   [navigate]
  // );

  useEffect(() => {
    setPaginationPage(urlPage);
  }, [urlPage]);

  const handleSearch = (values) => {
    let search_params = false;
    if (values.company_name) {
      search_params = {
        company_name: values.company_name,
      };
    }
    if (values.CR_number) {
      search_params = {
        CR_number: values.CR_number,
        ...search_params,
      };
    }
    if (values.phone) {
      search_params = {
        phone: values.phone,
        ...search_params,
      };
    }
    if (values.status) {
      search_params = {
        status: values.status,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      const params = new URLSearchParams();
      navigate({
        pathname: "/companies",
        search: "?" + params.toString(),
      });
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    setPaginationPage(1);
    // Reset Transactions List
    setSearchParams([]);
    const params = new URLSearchParams();
    navigate({
      pathname: "/companies",
      search: "?" + params.toString(),
    });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "companies",
      breadcrumbName: t("menu_companies"),
      icon: <AppstoreOutlined />,
    },
  ];

  const CompaniesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return (
          <p>
            {paginationData.current_page
              ? paginationData.per_page * (paginationData.current_page - 1) +
                (index + 1)
              : index + 1}
          </p>
        );
      },
    },
    {
      title: t("company"),
      render: (company) => {
        return (
          <>
            {company.logo && (
              <img
                src={company.logo}
                alt={t("business_logo")}
                style={{ maxWidth: "100px" }}
              />
            )}
            <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
              {company.name}
            </h3>
            <p>
              <strong> {t("business_type")} :</strong> {company.business_type}
              <br />
              <strong>CR # :</strong> {company.cr}
              {company.cr_certificate && (
                <Button
                  title={t("preview_cr_certificate")}
                  target="_blank"
                  href={company.cr_certificate}
                  type="link"
                >
                  <EyeOutlined />
                </Button>
              )}
              <br />
              <strong>VAT # :</strong>
              {company.is_vat_exempt ? " VAT Exempt" : company.vat}
              {company.vat_certificate && (
                <Button
                  title={t("preview_vat_certificate")}
                  target="_blank"
                  href={company.vat_certificate}
                  type="link"
                >
                  <EyeOutlined />
                </Button>
              )}
              <br />
            </p>
          </>
        );
      },
    },
    {
      title: t("owner"),
      render: (company) => {
        return (
          <>
            <p>
              <strong>Name :</strong> {company.owner_name}
              <br />
              <strong>Contact# :</strong> {company.owner_contact}
              <br />
              <br />
              <strong>CSR :</strong> {company.admin_staff?.name}
              <br />
            </p>
          </>
        );
      },
    },
    {
      title: t("no_of_employees"),
      align: "center",
      render: (company) => {
        return company.active_subscription
          ? company.active_subscription.used_users_count +
              " / " +
              company.active_subscription.user_licenses_count
          : company.no_of_employees + " / 1";
      },
    },
    {
      title: t("status"),
      align: "center",
      render: (company) => {
        if (company.status === COMPANY_STATUS_ACTIVE) {
          return <Tag color={"green"}>Active</Tag>;
        } else if (company.status === COMPANY_STATUS_KYC) {
          return <Tag color={"yellow"}>Submitting KYC</Tag>;
        } else if (company.status === COMPANY_STATUS_REVIEW) {
          return <Tag color={"blue"}>In Review</Tag>;
        } else if (company.status === COMPANY_STATUS_SUBSCRIPTION_ENDED) {
          return <Tag color={"volcano"}>Subscription Ended</Tag>;
        } else if (
          company.status === COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
        ) {
          return <Tag color={"orange"}>Invoice Generated</Tag>;
        } else if (company.status === COMPANY_STATUS_BLOCKED) {
          return <Tag color={"red"}>Blocked</Tag>;
        } else {
          return <Tag>-</Tag>;
        }
      },
    },
  ];

  const searchForm = (
    <>
      <Form
        name="search-company-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="company_name" label={t("company")}>
          <Input
            placeholder={t("search_company_name_here")}
            style={{ width: "200px" }}
          />
        </Form.Item>
        <Form.Item name="CR_number" label="CR#">
          <Input placeholder={t("search_cr_here")} style={{ width: "150px" }} />
        </Form.Item>
        <Form.Item name="phone" label="Phone#">
          <Input
            placeholder={t("search_phone_here")}
            style={{ width: "150px" }}
          />
        </Form.Item>
        <Form.Item name="status" label={t("status")}>
          <Select
            style={{ width: "200px" }}
            options={companyStatus.map((type) => ({
              label: type.text,
              value: type.id,
            }))}
          ></Select>
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_companies")}
          ghost={false}
          footer={searchForm}
        />
      </Card>
      <Card>
        <Table
          columns={CompaniesTableColumns}
          dataSource={companiesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
        {paginationData.total > paginationData.per_page && (
          <Pagination
            current={paginationPage}
            total={paginationData.total}
            pageSize={paginationData.per_page}
            onChange={handlePagination}
            showSizeChanger={false}
            style={{
              marginTop: "20px",
              textAlign: "right",
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Companies;

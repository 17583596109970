import { doGet, doPost } from "./config";

// -------------------- Auth API's-----------------------------------
export const userLogin = (data) => {
  return doPost("/login", data, "");
};

export const userLogout = (data) => {
  return doPost("/logout", data, localStorage.getItem("access_token"));
};

export const userRegister = (data) => {
  return doPost("/register", data, "", true);
};

export const userRegisterValidate = (data) => {
  return doPost("/register/validate", data, "", true);
};

export const getMyProfile = () => {
  return doGet("/is-login", localStorage.getItem("access_token"));
};

export const updateAuthDetails = (data) => {
  return doPost(
    `/users/update`,
    data,
    localStorage.getItem("access_token"),
    true
  );
};

export const sendOTPSMS = (data) => {
  return doPost(`/send-otp-sms`, data, "");
};

// Forgot
export const verifyForgotOtp = (data) => {
  return doPost("/verify/pin", data, "");
};

export const forgotPassword = (data) => {
  return doPost("/forgot-password", data, "");
};

export const resetPassword = (data) => {
  return doPost("/reset-password", data, "");
};

export const updateResellerProfile = (data) => {
  return doPost(
    `/reseller/update`,
    data,
    localStorage.getItem("access_token"),
    true
  );
};

// -------------------- Home API's ----------------------------------

export const getDashboardSummary = () => {
  return doGet(
    "/reseller/reseller-dashboard",
    localStorage.getItem("access_token"),
    {}
  );
};

// --------------------- Companies API's ------------------------------------

export const getCompanies = (data) => {
  return doGet(
    "/reseller/reseller-companies",
    localStorage.getItem("access_token"),
    data
  );
};
export const getCompany = (company_id, data) => {
  return doGet(
    "/reseller/companies/" + company_id,
    localStorage.getItem("access_token"),
    data
  );
};

// --------------------- Helpdesk API's -------------------------------

export const getTicketsCount = (data) => {
  return doGet(
    "/reseller/helpdesk/tickets",
    localStorage.getItem("access_token"),
    data
  );
};
export const getNewTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/new?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getInprogressTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/inprogress?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDoneTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/done?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getClosedTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/closed?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getLateTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/late?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDelayedTickets = (page) => {
  return doGet(
    "/reseller/helpdesk/tickets/delayed?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const updateTickets = (id, data) => {
  return doPost(
    "/reseller/helpdesk/tickets/" + id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const forwardTickets = (id) => {
  return doPost(
    "/reseller/helpdesk/tickets/" + id + "/forward",
    {},
    localStorage.getItem("access_token")
  );
};

import "./Dashboard.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Menu, Layout, Button, Dropdown, Space } from "antd";
import {
  DownOutlined,
  UserOutlined,
  GlobalOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PhoneOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import AppRoutes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateAuthDetails, userLogout } from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import {
  RESELLER_STATUS_ACTIVE,
  RESELLER_STATUS_INACTIVE,
  RESELLER_STATUS_KYC,
  RESELLER_STATUS_REJECTED,
  RESELLER_STATUS_REVIEW,
  SIDEBAR_MENU_KEY_HOME,
} from "../../configs/constants";
import { useNavigate } from "react-router-dom";
import Profile from "../Content/Profile/Profile";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const {
    authUser,
    activeMenu,
    setActiveMenu,
    appConfig,
    setAppConfigWrapper,
    isSiderCollapsed,
    setIsSiderCollapsed,
  } = useContext(AppContext);
  const [deviceWidth, setDeviceWidth] = useState("");
  const [showPageOnly, setShowPageOnly] = useState(false);
  const [blockRouting, setBlockRouting] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    if (authUser.status === RESELLER_STATUS_ACTIVE) {
      setBlockRouting(false);
      setShowPageOnly(false);
    } else {
      if (
        authUser.status === RESELLER_STATUS_KYC ||
        authUser.status === RESELLER_STATUS_REJECTED ||
        authUser.status === RESELLER_STATUS_REVIEW ||
        authUser.status === RESELLER_STATUS_INACTIVE
      ) {
        setBlockRouting(true);
        setShowPageOnly("PROFILE");
      }
    }

    setAppConfigWrapper(authUser.app_config);
    // eslint-disable-next-line
  }, [authUser.status]);

  useEffect(() => {
    if (
      authUser &&
      Object.keys(appConfig).length > 0 &&
      JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
    ) {
      const formData = new FormData();
      formData.append("app_config", JSON.stringify(appConfig));
      updateAuthDetails(formData).then((res) => {
        console.log("App Config Updated");
      });
    }
  }, [appConfig, authUser]);

  useEffect(() => {
    setDeviceWidth(windowWidth.current);
  }, [windowWidth]);

  const saveCurrentPageView = (key) => {
    setActiveMenu(key);
    localStorage.setItem(
      "last_visited_page",
      JSON.stringify({
        key: key,
        path: window.location.pathname,
      })
    );
  };

  const logoutUser = () => {
    userLogout().then(function () {
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    });
  };

  const UserOptions = [
    {
      key: "1",
      label: (
        <div>
          <PhoneOutlined rotate={90} style={{ color: "#6301f2" }} /> +
          {authUser.phone}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            logoutUser();
          }}
        >
          <LogoutOutlined style={{ color: "red" }} /> {t("logout")}
        </div>
      ),
    },
  ];

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "#ffffff",
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          lineHeight: "unset",
          padding: "0 20px",
          boxShadow: "#0000005c 0px 0px 30px -10px",
          maxHeight: "65px",
          height: "100vh",
        }}
      >
        <Space size={20} style={{ flexGrow: "1", justifyContent: "start" }}>
          <Button
            onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
            shape="round"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0",
              height: "unset",
            }}
          >
            {appConfig.direction === "ltr" ? (
              isSiderCollapsed ? (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              )
            ) : isSiderCollapsed ? (
              <MenuFoldOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
            )}
          </Button>
          <img
            src="/logo.png"
            alt="AnyPOS Logo"
            style={{
              width: "100%",
              maxWidth: "60px",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/home");
              setActiveMenu(SIDEBAR_MENU_KEY_HOME);
            }}
          />
        </Space>
        <Space size={20} style={{ flexGrow: "1", justifyContent: "end" }}>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: LangOptions,
            }}
          >
            <Button
              shape="round"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                height: "unset",
              }}
            >
              <GlobalOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
              {appConfig.direction === "ltr" ? "English" : "عربي"}
              <span style={{ margin: "0 8px" }}>
                <DownOutlined
                  style={{
                    fontSize: "12px",
                  }}
                />
              </span>
            </Button>
          </Dropdown>
          <Dropdown menu={{ items: UserOptions }} trigger={["click"]}>
            <Button
              shape="round"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                height: "unset",
              }}
            >
              <UserOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
              {authUser.name + " "}
              <span style={{ margin: "0 8px" }}>
                <DownOutlined
                  style={{
                    fontSize: "12px",
                  }}
                />
              </span>
            </Button>
          </Dropdown>
        </Space>
      </Header>
      <Layout style={{ position: "relative" }}>
        {deviceWidth > 768 && (
          <Sider
            collapsible
            theme="light"
            trigger={null}
            defaultCollapsed={false}
            collapsed={isSiderCollapsed}
            collapsedWidth={150}
            width={220}
            style={{
              boxShadow: "#0000005c 0px 0px 30px -10px",
              overflow: "auto",
              position: "fixed",
              left: appConfig.direction === "ltr" ? 0 : "unset",
              right: appConfig.direction === "ltr" ? "unset" : 0,
              top: 65,
              bottom: 0,
            }}
          >
            <Menu
              mode="vertical"
              className={isSiderCollapsed ? "custom-menu" : ""}
              selectedKeys={[activeMenu]}
              items={filterPagesByAddons(ContentPages, authUser)
                .map((page) => {
                  if (page.showInSider) {
                    if (page.subMenus && page.subMenus.length > 0) {
                      return {
                        key: page.name,
                        icon: React.createElement(page.icon),
                        label: t(page.text),
                        disabled: blockRouting,
                        children: filterPagesByAddons(
                          page.subMenus,
                          authUser
                        ).map((subPage) => ({
                          key: subPage.name,
                          icon: React.createElement(subPage.icon),
                          label: (
                            <Link to={subPage.path}>{t(subPage.text)}</Link>
                          ),
                          onClick: () => saveCurrentPageView(subPage.name),
                          className: "custom-menu-popup",
                        })),
                      };
                    } else {
                      return {
                        key: page.name,
                        icon: React.createElement(page.icon),
                        label: <Link to={page.path}>{t(page.text)}</Link>,
                        onClick: () => saveCurrentPageView(page.name),
                        disabled: blockRouting,
                      };
                    }
                  }
                  return null;
                })
                .filter(Boolean)} // Filter out any null values that might have been returned
            />
          </Sider>
        )}
        <Layout
          style={{
            marginLeft:
              appConfig.direction === "ltr"
                ? isSiderCollapsed
                  ? 150
                  : 220
                : 0,
            marginRight:
              appConfig.direction === "ltr" ? 0 : isSiderCollapsed ? 150 : 220,
          }}
        >
          <Content
            style={{
              margin: "20px",
              padding: "0",
              background: "transparent",
              minHeight: "calc(100vh - 105px)",
            }}
          >
            {blockRouting ? (
              <>{showPageOnly === "PROFILE" && <Profile />}</>
            ) : (
              <AppRoutes />
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Dashboard;

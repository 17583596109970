import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Radio,
  message,
  Spin,
  Upload,
} from "antd";
import { EyeOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../../components/ImageUpload";
import { updateResellerProfile } from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";

const ProfileUpdate = () => {
  const { authUser, setAuthUser } = useContext(AppContext);
  const [userType, setUserType] = useState([]);
  const [userIDImage, setUserIDImage] = useState(false);
  const [companyRegistrationDoc, setCompanyRegistrationDoc] = useState(false);
  const [loading, setloading] = useState(false);
  const [updateForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    updateForm.setFieldsValue({
      id: authUser.id,
      first_name: authUser.first_name,
      last_name: authUser.last_name,
      phone: authUser.phone,
      email: authUser.email,
      user_type: authUser.user_type,
      reseller_company_name: authUser.reseller_company_name,
    });
    setUserType(authUser.user_type);
    setUserIDImage(authUser.user_photo_id);
    setCompanyRegistrationDoc(authUser.company_registration_document);
  }, [updateForm, authUser]);

  const onFinish = () => {
    if (!companyRegistrationDoc && userType === "company") {
      message.error(t("validate_company_registration_document_required"));
    } else if (!userIDImage) {
      message.error(t("validate_photo_of_id_required"));
    } else {
      updateForm.validateFields().then((values) => {
        setloading(true);
        // Set Form values as FormData
        const formData = new window.FormData();
        if (typeof userIDImage == "object" && userIDImage) {
          formData.append("user_photo_id", userIDImage);
        }
        if (
          typeof companyRegistrationDoc == "object" &&
          companyRegistrationDoc &&
          userType !== "individual"
        ) {
          formData.append(
            "company_registration_document",
            companyRegistrationDoc
          );
        }
        Object.keys(values).map((key) => {
          formData.append(key, values[key]);
          return true;
        });
        updateResellerProfile(formData)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              console.log(data);
              setAuthUser(data.user);
              window.location.reload();
              // Success
              NotificationWithIcon("success", t("status_updated_successfully"));
            } else {
              // Error
              let errorMessages = Object.values(data.errors);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setloading(false);
          });
      });
    }
  };

  function beforeUpload(file) {
    const is5MB = file.size / 1024 / 1024 < 5;
    if (!is5MB) {
      message.error(t("validate_file_size_5"));
    }
    return is5MB;
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="user-update"
        layout="vertical"
        onFinish={onFinish}
        form={updateForm}
      >
        <Row gutter={[20, 10]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="first_name"
              label={t("first_name")}
              rules={[
                {
                  required: true,
                  message: t("validate_first_name_required"),
                  whitespace: true,
                },
                {
                  min: 3,
                  message: t("validate_first_name_min_characters"),
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="last_name"
              label={t("last_name")}
              rules={[
                {
                  required: true,
                  message: t("validate_last_name_required"),
                  whitespace: true,
                },
                {
                  min: 3,
                  message: t("validate_last_name_min_characters"),
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="phone"
              label={t("phone_number") + " eg: +966 5xxxxxxx"}
              rules={[
                {
                  required: true,
                  message: t("validate_phone_required"),
                },
                {
                  min: 12,
                  message: t("validate_phone_exact_limit"),
                },
                {
                  max: 12,
                  message: t("validate_phone_exact_limit"),
                },
              ]}
              style={{ textAlign: "left" }}
              hasFeedback
            >
              <PhoneInput
                localization={["sa"]}
                masks={{ sa: "(...) ..-..-.." }}
                placeholder="+966 (123) 45-67-89"
                country={"sa"}
                onlyCountries={["sa"]}
                inputStyle={{
                  width: "100%",
                }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="email"
              label={t("email")}
              rules={[
                {
                  required: true,
                  message: t("validate_email_required"),
                },
                {
                  type: "email",
                  message: t("validate_email_invalid"),
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="user_type"
              label={t("type")}
              rules={[
                {
                  required: true,
                  message: t("validate_type_required"),
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              >
                <Radio value="individual">{t("individual")}</Radio>
                <Radio value="company">{t("company")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {(userType === "individual" || userType === "company") && (
            <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={5}>
              <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                <div className="ant-form-item-label">
                  <label
                    className="ant-form-item-required"
                    title={t("photo_of_id")}
                  >
                    {t("photo_of_id")}
                  </label>
                </div>
                <ImageUpload
                  name="image"
                  callbackSetImageFile={setUserIDImage}
                  image={userIDImage}
                />
                {authUser.user_photo_id && (
                  <Button
                    type="primary"
                    href={authUser.user_photo_id}
                    target="_blank"
                  >
                    <EyeOutlined /> {t("preview")}
                  </Button>
                )}
              </div>
            </Col>
          )}
          {userType === "company" && (
            <>
              <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={7}>
                <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                  <div className="ant-form-item-label">
                    <label
                      className="ant-form-item-required"
                      title={t("company_registration_document")}
                    >
                      {t("company_registration_document")}
                    </label>
                  </div>
                  <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    accept=".pdf"
                    beforeUpload={beforeUpload}
                    customRequest={(info) => {
                      setCompanyRegistrationDoc(info.file);
                    }}
                  >
                    {companyRegistrationDoc ? (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>{t("change")}</div>
                      </div>
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>{t("upload")}</div>
                      </div>
                    )}
                    {companyRegistrationDoc?.name}
                  </Upload.Dragger>
                  {authUser.company_registration_document && (
                    <Button
                      type="primary"
                      href={authUser.company_registration_document}
                      target="_blank"
                      style={{ marginTop: "10px" }}
                    >
                      <EyeOutlined /> {t("preview")}
                    </Button>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="reseller_company_name"
                  label={t("company_name")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_company_name_required"),
                      whitespace: true,
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
        <center>
          <Form.Item style={{ margin: "20px 0" }}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              style={{ maxWidth: "300px" }}
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </center>
      </Form>
    </Spin>
  );
};

export default ProfileUpdate;
